<template>
  <div class="wrapper">
    <div class="bg top">
      <div class="container">
        <h2>CLUB DE NETWORKING PROFESIONAL</h2>
        <p>
          El Club de Ejecutivos, ha creado una amplia red de profesionales
          que asesoran o trabajan con empresas nacionales e internacionales,
          muchas de ellas de primer nivel facilitando la incorporación al
          trabajo de miles de profesionales y recién graduados. 
          <br>
          El Club de Ejecutivos está comprometido a:
        </p>
        <div class="items">
          <b-row class="item">
            <b-col cols="12" class="col-lg-1 img">
              <img loading="lazy" alt="icon" src="/images/work/networking-1.png" />
            </b-col>
            <b-col cols="11" class="col-lg-10 text">
              <span>
                Gestionar alumnos a través de los programas Erasmus en toda
                Europa para sumergirlos en prácticas profesionales facilitándoles
                el apoyo a la salida laboral y al desarrollo de su carrera
                profesional.
              </span>
            </b-col>
          </b-row>
          <b-row class="item">
            <b-col cols="12" class="col-lg-1 img">
              <img loading="lazy" alt="icon" src="/images/work/networking-2.png" />
            </b-col>
            <b-col cols="11" class="col-lg-10 text">
              <span>
                Generar eventos donde los protagonistas son directivos y
                profesionalesque actúan como conferenciantes o directores
                de seminarios para favorecer mejor el conocimiento del
                mercado laboral al alumno.
              </span>
            </b-col>
          </b-row>
          <b-row class="item">
            <b-col cols="12" class="col-lg-1 img">
              <img loading="lazy" alt="icon" src="/images/work/networking-3.png" />
            </b-col>
            <b-col cols="11" class="col-lg-10 text">
              <span>
              La organización de eventos de Alta Dirección como desayunos
              o almuerzos de trabajo, organización de jornadas y seminarios
              de contenido empresarial o profesional dónde se facilita el
              encuentro entre profesionales que buscan empleo y directores
              de recursos humanos que hacen selección.
              </span>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <b-row>
          <b-col cols="12" class="col-lg-4 text">
            <p>
              Fortalecemos los vínculos entre alumnos y profesionales que buscan
              mejores oportunidades laborales con  empresarios como estrategia
              para hacer crecer estas relaciones a través de multitud de actuaciones,
              entre las que destacan eventos como <strong>Business Break, Hoy es Marketing
              digital, El trabajador remoto</strong>, y diversas ceremonias enmarcadas en
              eventos de gran calado a los que  asisten familiares, profesores,
              empresarios y relevantes personas del entorno político, económico
              y social.
            </p>
          </b-col>
          <b-col cols="12" class="col-lg-8">
            <img loading="lazy" alt="icon" src="/images/work/networking.png" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('empleabilidad.networking'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.networking') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.networking') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .bg.top {
    background: #d3d5d6;
    padding: 50px 0;
    text-align: left;
  }

  h2 {
    font-weight: 800;
  }

  p {
    font-size: 0.9rem;
  }

  .items {
    .item {
      margin-bottom: 20px;

      .text {
        margin-left: 20px;
        font-size: 0.9rem;
        margin: auto 0;
      }

      .img {
        text-align: center;
      }

      img {
        margin: 5px 0;
        width: 80px;
      }
    }
  }

  .bottom {
    padding: 50px 0;

    .text {
      margin: auto;

      p {
        text-align: left;
        font-size: 1rem;
      }
    }

    img {
      width: 100%;
    }
  }
</style>